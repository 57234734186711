import React, { useDebugValue, useEffect, useRef, useState } from "react"
import { graphql, Link } from "gatsby"

import { connect } from 'react-redux';
import { addToCartMessage } from '../store/actions';

import Layout from "../components/layout"
import SEO from "../components/seo"

import "../scss/products.scss";
import 'uikit/dist/css/uikit.css'

import headerBgImage from "../assets/img/pexels-pixabay-158385.jpg"
import Button from "../components/basic-components/button";
import CustomMarkdown from "../utils/customMarkdown"
import CategoryBar from "../components/category-bar";
import { MdOutlineArrowBackIosNew, MdOutlineArrowForwardIos } from 'react-icons/md'
import { FaCheckCircle, FaHourglassEnd } from "react-icons/fa";
import { navigate } from "gatsby-link";

import { getAllProducts } from '../store/selectors';

import { emptyCartMessage } from '../store/actions';





var productImage = "https://picsum.photos/seed/picsum/1200";
var productMaterial = "https://picsum.photos/seed/picsum/100";

const BaseUrl = "https://memorials.phaeria.com"

var headerTitle = "Beautiful, distinctive and affordable memorials";
var headerDescription = "Dolore non qui ea quis nulla. Qui velit cupidatat Lorem voluptate id aliquip. In eiusmod non cillum minim Lorem duis.";
var headerCTAPrimaryLabel = "View Collections";
var headerCTAPrimaryUrl = "#0";
var headerCTASecondaryLabel = "Speak to one of the team";
var headerCTASecondaryUrl = "#0";


var pageData = {
  headerTitle,
  headerDescription,
  headerCTAPrimaryLabel,
  headerCTAPrimaryUrl,
  headerCTASecondaryLabel,
  headerCTASecondaryUrl,
  headerBgImage,
  headerLeftBgColor: '',
  headerRightBgColor: '',
};


export const query = graphql`
  query ProductsQuery($id: Int) {
    jrmProducts(Id: {eq: $id}) {
      PayOnline
      HidePrices
      Name
      Description
      AttractivePrice
      NotAttractivePrice
      Id
      
    }

    allJrmProductImages(filter: {SDItemId: {eq: $id}}) {
      edges {
        node {
          Path
          SDItemId
          Description
          Id
        }
      }
    }

    allJrmCategories {
      nodes {
        Id
        Name
      }
    }

    allJrmFilteredCategories {
      nodes {
          Id
      }
    }

    allJrmProductsMaterials(filter: {Id: {eq: $id}}) {
      nodes {
        Name
        Id
        AttractivePrice
        Image
        BandPrice
        MaterialName
        NotAttractivePrice
        MaterialId
        Image
        ItemsShowProductOptionsOnFrontend
      }
    }

    

    allJrmProductsSizes(filter: {Id: {eq: $id}}) {
      nodes {
        Name
        Id
        AttractivePrice
        Height
        Length
        Width
        PriceType
        SizeId
        SizePrice
        NotAttractivePrice
      }
    }  
    allJrmProductsFinishes(filter: {Id: {eq: $id}}) {
      nodes {
        Name
        Id
        FinishesId
        FinishesName
        FinishesPrice
      }
    }

  }
`



const Products = ({ data, products, addToCart, emptyCart }) => {


  //console.log(products, "tetstt")

  //local vars
  // var materialsVar = {materialId: "", materialName: ""}
  var materialsList = []
  var sizesList = []

  var orderProducts = []



  var selectedBandPrice
  var selectedPriceType
  var selectedSizePrice
  var selectedInitialPrice
  var selectedMaterialId
  var selectedSizeId
  var netPrice
  var VAT = 1.2
  var priceWithVat
  var selectedFinishPrice;
  var selectedFinishId;
  var selectedFinishName;



  var FilteredCategories = []
  data.allJrmCategories.nodes.forEach((item, index) => {
    var exists = data.allJrmFilteredCategories.nodes.find(filteredItem => filteredItem.Id === item.Id)
    if (exists) {
      FilteredCategories.push({
        Id: item.Id,
        Name: item.Name
      })
    }
  })
  const finishes = data.allJrmProductsFinishes.nodes



  // console.log('material: ', selectedMaterialId, 'size: ', selectedSizeId, selectedFinishId)





  // display size/material/finishes

  const productOptions = data.allJrmProductsMaterials?.nodes[0]?.ItemsShowProductOptionsOnFrontend !== null ? data.allJrmProductsMaterials.nodes[0]?.ItemsShowProductOptionsOnFrontend.split(',') : ''






  const [prodQty, setCount] = useState(1);

  const handleIncrement = () => {
    setCount(prevCount => prevCount + 1)
    // setPrice(prodPrice * prodQty)
    /*
    console.log("increment");
    if (typeof window !== 'undefined'){
     console.log("triggerGA");
     //window.ga('send', 'event', 'TriggerIncrement', 'submit');
     window.gtag('event', 'trigger-increment');
    }
    */
  }
  const handleDecrement = () => {
    setCount(prevCount => prevCount > 1 ? prevCount - 1 : prevCount = 1)
    // setPrice(prodPrice * prodQty)
  }

  //dummy product
  var product = {
    name: '',
    id: '',
    itemId: '',
    materialId: '',
    sizeId: '',
    quantity: '',
    price: '',
    image: '',
    hidePrice: false,
    finishId: ''

  }



  // function showMessage(){
  //   console.log("message")
  //   const msg = document.querySelector(".product__add__btn__message")
  //   // msg.classList.add("asdf")
  //   msg.classList
  //   console.log(msg)
  // }

  const [addedToBasket, setActive] = useState(false)

  const toggleAddedToBasket = () => {
    setActive(true)
    // console.log("added: ",addedToBasket)
  }


  //vars with content from graphql
  const page = data.jrmProducts
  const productImages = data.allJrmProductImages.edges.length > 0 ? data.allJrmProductImages : null
  const productMaterials = data.allJrmProductsMaterials
  const productSizes = data.allJrmProductsSizes


  const [selectedFinishTypeId, setSelectedFinishTypeId] = useState(finishes[0].FinishesId)
  const [selectedFinishTypePrice, setSelectedFinishTypePrice] = useState(finishes[0].FinishesPrice)




  //initialize finishes variables 


  finishes.map((item, index) => {
    if (index === 0) {
      selectedFinishId = item.FinishesId

      // setSelectedFinishId(item.FinishesId)
      selectedFinishPrice = item.FinishesPrice
      selectedFinishName = item.FinishesName
    }
  })



  productMaterials.nodes.map((item, key) => {
    // data from the query contains duplicate values and I created a new array that contains only unique values

    if (key === 0) {
      //try to refactor with useState to save the state when I change size or finish

      // selectedBandPrice = item.BandPrice
      // selectedMaterialId = item.MaterialId
    }


    var findMaterials = materialsList.find(x => x.materialId === item.MaterialId)

    if (!findMaterials) {
      materialsList.push({
        materialId: item.MaterialId,
        materialName: item.MaterialName,
        bandPrice: item.BandPrice,
        materialImage: item.Image
      })
    }


    return ("")
  })

  productSizes.nodes.map((item, key) => {
    // materialsList.push(item.MaterialName, item.MaterialId)

    // data from the query contains duplicate values and I created a new array that contains only unique values


    if (key === 0) {
      selectedPriceType = item.PriceType
      selectedSizePrice = item.SizePrice
      // selectedSizeId = item.SizeId
    }
    var findSizes = sizesList.find(x => x.sizeId === item.SizeId)
    if (!findSizes) {
      sizesList.push({
        sizeId: item.SizeId,
        width: item.Width,
        height: item.Height,
        length: item.Length,
        priceType: item.PriceType,
        sizePrice: item.SizePrice

      })
    }
    // updatePrice()
    return ("")
  })


  // const [selectedFinishId, setSelectedFinishId] = useState(finishes[0].FinishesPrice)
  // const [selectedMaterialId, setSelectedMaterialId] = useState(productMaterials[0].MaterialId)
  // const [selectedSizeId, setSelectedSizeId] = useState(productSizes[0].SizeId)

  calcNetPrice()

  const [prodPrice, setPrice] = useState()



  //functions to handle the selection of materials and sizes

  const [activeId, setActiveId] = useState()



  // const [selectedFinishType, setSelectedFinishType] = useState(finishes[0].FinishesId)



  function materialHandle(attr, id) {

    selectedBandPrice = attr.bandPrice
    selectedMaterialId = attr.materialId


    setActiveId(id)
    updatePrice()
  }





  function sizeHandle(attr) {
    selectedPriceType = attr.priceType
    selectedSizePrice = attr.sizePrice
    selectedSizeId = attr.sizeId
    //  console.log('size id',attr.sizeId)
    // setSelectedSizeId(attr.sizeId)

    updatePrice()
  }


  function handleFinish(item) {
    setSelectedFinishTypeId(item.FinishesId)
    selectedFinishPrice = item.FinishesPrice
    selectedFinishId = item.FinishesId
    selectedFinishName = item.FinishesName


    setSelectedFinishTypePrice(item.FinishesPrice)

    updatePrice()
  }

  function updatePrice() {
    calcNetPrice()
    // setPrice(netPrice )
    setPrice(Math.round(netPrice * VAT * 100) / 100)

    //updates product basket object's info
    product.name = page.Name
    product.id = page.Id + '-' + selectedMaterialId + '-' + selectedSizeId + '-' + selectedFinishTypeId
    product.itemId = page.Id
    product.materialId = selectedMaterialId
    product.sizeId = selectedSizeId
    product.price = netPrice
    product.hidePrice = page.HidePrices
    product.finishId = selectedFinishTypeId

    // console.log('product.hide', product.hidePrice)


  }

  useEffect(() => {
    updatePrice()
  }, [selectedFinishTypeId])

  //sets the initial product var to be able to add the product to basket without making any other selections on the page
  product.name = page.Name
  product.id = page.Id + '-' + selectedMaterialId + '-' + selectedSizeId + '-' + selectedFinishTypeId
  product.itemId = page.Id
  product.materialId = selectedMaterialId
  product.sizeId = selectedSizeId
  product.finishId = selectedFinishTypeId
  // product.price = netPrice 
  product.price = netPrice * VAT
  product.hidePrice = page.HidePrices



  // console.log('product.hide', product.hidePrice)

  function calcNetPrice() {

    selectedInitialPrice = (selectedPriceType === 0) ? page.AttractivePrice : page.NotAttractivePrice
    //console.log("calcNetPrice:", page.AttractivePrice, page.NotAttractivePrice, selectedInitialPrice, selectedSizePrice, selectedBandPrice, selectedFinishPrice);
    if (!selectedSizePrice) { selectedSizePrice = 100; }
    if (!selectedBandPrice) { selectedBandPrice = 100; }
    if (!selectedFinishPrice) { selectedFinishPrice = 100; }
    // the selected price * 100 and then divide 100 to round on 2 decimals. the rest are the percentage per size/band/finish if added on.
    netPrice = Math.round(selectedInitialPrice * 100 * selectedSizePrice / 100 * selectedBandPrice / 100 * selectedFinishPrice / 100) / 100
    //console.log("checkPrice:", netPrice)
  }

  // onClick full width image


  const [openModal, setOpenModal] = useState(false)
  const [index, setIndex] = useState(0)
  const source = []

  productImages.edges.map((item) => {

    source.push({ image: process.env.GATSBY_IMAGE_PATH_URL + "/" + item.node.Path, description: item.node.Description })

  })


  //get the current src 
  const getSrc = (src, id) => {
    setOpenModal(true)
    const index = source.findIndex(item => item.image === src)
    setIndex(index)
  }
  //stop body scroll when modal is open

  if (openModal) {
    document.body.setAttribute('style', `position:fixed; top: 0; left:0; right:0;`)
  } else {
    document.body.setAttribute('style', ``)
  }



  //Click outside the image to close the modal

  const imageContainer = useRef()

  useEffect(() => {
    const handleMouseDown = (e) => {
      if (!imageContainer.current.contains(e.target)) {
        setOpenModal(false)
      }
    }
    document.addEventListener('mousedown', handleMouseDown);

    return () => {
      document.removeEventListener('mousedown', handleMouseDown)

    }
  })

  // check if index is the last of the array or the first element

  function checkIndex(number) {
    if (number < 0) {
      return source.length - 1;
    }

    if (number > source.length - 1) {
      return 0;
    }
    return number;
  }

  //key down events: close modal, change slide

  useEffect(() => {
    const handleKeyDown = (e) => {
      const lastIndex = source.length - 1;
      //console.log(lastIndex)

      if (e.key === "ArrowRight") {
        setIndex((index) => {
          let newIndex = index + 1;
          return checkIndex(newIndex);
        });
      }
      if (e.key === 'ArrowLeft') {
        setIndex((index) => {
          let newIndex = index - 1;
          return checkIndex(newIndex);
        });
      }
      if (e.key === "Escape") {
        setOpenModal(false)
      }
    }
    document.addEventListener('keydown', handleKeyDown)
    return () => {
      document.removeEventListener('keydown', handleKeyDown)
    }
  })


  const handleGtag = (title) => {
    if (typeof window !== 'undefined' && typeof window.gtag !== 'undefined') {

      window.gtag('event', 'add_to_cart', { 'event_category': 'ecommerce', 'event_label': `${title}` });

    }
  }



  const [imageId, setImageId] = useState('')
  useDebugValue(imageId == null ? 'no id' : imageId)



  const imagesRef = useRef([])
  imagesRef.current = []

  const addToRefs = (elem) => {
    if (elem && !imagesRef.current.includes(elem)) {
      imagesRef.current.push(elem)
    }
  }

  const checkoutResponse = useRef();
  const checkoutBasketContents = useRef();
  const checkoutCustomerDetails = useRef();

  const sendOrderTest = event => {

    var order = {

      "Customer": {
        "Email": document.getElementById("order-email").value,
        "Salutation": document.getElementById("order-salutation").value,
        "FirstName": document.getElementById("order-first-name").value,
        "Surname": document.getElementById("order-surname").value,
        "Address": document.getElementById("order-address").value,
        "Postcode": document.getElementById("order-postcode").value,
        "Mobile": document.getElementById("order-phone").value,
        "BillingContactName": document.getElementById("order-first-name").value,
        "BillingSalutation": document.getElementById("order-salutation").value,
        "BillingAddress": document.getElementById("order-address").value,
        "BillingPostcode": document.getElementById("order-postcode").value,
        "Cemetery": document.getElementById("order-cemetery").value
      },

      // "Products": [{
      //     "ItemId": "13",
      //     "MaterialId": "34",
      //     "SizeId": "14",
      //     "Quatity": "2"
      // }],
      "Cemetery": document.getElementById("order-cemetery").value,

      "Products": orderProducts,

      "TotalPrice": "0.00"
    }

    //   fname: document.getElementById("fname").value,
    //   email: document.getElementById("email").value,
    //   message: document.getElementById("message").value

    // console.log(order);
    var submitBtn = document.getElementById("confirm-order").firstChild;
    submitBtn.innerHTML = '<svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 512 512" class="spinner" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path d="M288 39.056v16.659c0 10.804 7.281 20.159 17.686 23.066C383.204 100.434 440 171.518 440 256c0 101.689-82.295 184-184 184-101.689 0-184-82.295-184-184 0-84.47 56.786-155.564 134.312-177.219C216.719 75.874 224 66.517 224 55.712V39.064c0-15.709-14.834-27.153-30.046-23.234C86.603 43.482 7.394 141.206 8.003 257.332c.72 137.052 111.477 246.956 248.531 246.667C393.255 503.711 504 392.788 504 256c0-115.633-79.14-212.779-186.211-240.236C302.678 11.889 288 23.456 288 39.056z"></path></svg>';
    submitBtn.classList.add("form-submitting");
    // document.getElementById("confirm-order").firstChild.classList.add("form-submitting");

    var body = {
      data: JSON.stringify(order)
    };

    fetch(process.env.GATSBY_CHECKOUT_FETCH_ADDRESS, {
      method: "POST",
      body: JSON.stringify(body),
      headers: {
        'Content-Type': 'application/json'
      }

    }).then(response => {
      //  console.log(response)
      //  console.log("status" + response.status)
      //  console.log("quoteNo" + response.quoteNo)
      // console.log("response: " + response.ok)
      if (!response.ok) {
        // throw new Error('network response was not OK')
        // console.log("error sending")
        submitBtn.innerHTML = "Oops... Try again!"
        submitBtn.classList.remove("form-submitting")

      } else {

        response.json().then(json => {

          if (json.status === "Success") {
            // console.log("sent ok")

            if (typeof window !== 'undefined' && typeof window.gtag !== 'undefined'){
                window.gtag('event', 'complete_quote', {'value': `0.00`, 'currency': 'GBP'})
            }

            submitBtn.innerHTML = "Quotation requested!"
            document.getElementById("order-response-number").innerHTML = json.quoteNo
            //document.getElementById("order-response-payment-url").innerHTML = json.onlinePaymentUrl
            document.getElementById("modalTitle").classList.add("uk-hidden")
            document.getElementById("divider").classList.add("uk-hidden")
            submitBtn.classList.remove("form-submitting")
            checkoutResponse.current.firstChild.classList.remove("uk-hidden")
            checkoutCustomerDetails.current.firstChild.classList.add("uk-hidden")
            //emptyCart()
            emptyCart({})



            if (json.onlinePaymentUrl) {
              navigate(json.onlinePaymentUrl)
            }




          }
          else {
            // console.log("sent but not ok: " + json.message)
            submitBtn.classList.remove("form-submitting")
            submitBtn.classList.add("red-state")
            submitBtn.innerHTML = "Something went wrong... Try again!"

          }
        })


      }



    }
    )
    // .then(result => console.log("result" + result.json()))
    // console.log("Message sent");

    event.preventDefault();
  }



  return (

    <Layout pageData={pageData} headerShow={false}>

      <SEO title={page.Name} />

      {/* <CategoryBar data={FilteredCategories}></CategoryBar> */}
      {/* <DebugCart></DebugCart> */}


      <div className="section-container">
        <div className="products-section-wrapper">
          <div className="tiles-section uk-child-width-1-2@s  uk-grid-small uk-grid-column-small" data-uk-grid>
            <div className="left-column-container">
              <div className="uk-position-relative uk-visible-toggle uk-light" tabindex="-1" data-uk-slideshow="animation: scale">
                {/* <p>test</p> */}
                <ul className="uk-slideshow-items mobile-images-ratio" >
                  {productImages ? productImages.edges.map((item, index) => {
                    if (index === 0) product.image = process.env.GATSBY_IMAGE_PATH_URL + "/" + item.node.Path
                    return (
                      <li key={index} className={`product-slideshow-item-background ${item.node.Id === imageId ? 'uk-active' : ''}`} ref={addToRefs}>
                        <div className="uk-position-cover uk-transform-origin-top-center">

                          <img data-src={process.env.GATSBY_IMAGE_PATH_URL + "/" + item.node.Path} className='full-width-image' alt="" data-uk-cover data-uk-img="target: !ul > :last-child, !* +*" onClick={e => getSrc(e.target.src)} />
                        </div>
                      </li>
                    )
                  }) : <li>
                    <img data-src={process.env.GATSBY_DEFAULT_NA_IMAGE} width="1800" height="1200" alt="" className='full-width-image' data-uk-cover data-uk-img="target: !ul > :last-child, !* +*" onClick={e => getSrc(e.target.src)} />
                  </li>}
                </ul>




                <div className={`${openModal ? "show-modal " : "modal"}`}>
                  <span className='number-of-images'>{index + 1 + '/' + source.length}</span>
                  <span className="close" onClick={() => setOpenModal(false)}>&times;</span>
                  <div className='uk-position-center image-container ' ref={imageContainer} >


                    {source.length > 1 ? <span data-uk-icon="icon:  chevron-left; ratio: 2" className='slideshow-arrow-left' onClick={() => setIndex((index) => {let newIndex = index - 1; return checkIndex(newIndex);})}></span> : ''}

                    <div className="image-parent" tabindex="0">
                      <img src={source[index].image} className='slide-image' />
                    </div>

                    {source.length > 1 ? <span data-uk-icon="icon: chevron-right; ratio: 2" className='slideshow-arrow-right' onClick={() => setIndex((index) => {let newIndex = index + 1; return checkIndex(newIndex);})}></span> : ''}
                  </div>


                </div>

                <button className="uk-position-center-left uk-position-small uk-hidden-hover" href="#0" data-uk-slidenav-previous data-uk-slideshow-item="previous" aria-label="view-previous-image"
                  onClick={() => setIndex((index) => {
                    let newIndex = index - 1;
                    return checkIndex(newIndex);
                  })}
                ></button>

                <button className="uk-position-center-right uk-position-small uk-hidden-hover" href="#0" data-uk-slidenav-next data-uk-slideshow-item="next" aria-label="view-next-image"
                  onClick={() => setIndex((index) => {
                    let newIndex = index + 1;
                    return checkIndex(newIndex);
                  })}
                ></button>

              </div>

              {/* images slideshow */}
              <div className="slideshow-container">

                <div data-uk-slider>
                  <ul className="uk-slider-items uk-child-width-1-3 uk-grid-match slideshow-single-image-container">
                    {
                      productImages ? productImages.edges.map((item, index) => {

                        return <li className="slideshow-single-image" key={index}>
                          <img src={process.env.GATSBY_IMAGE_PATH_URL + "/" + item.node.Path} height='150' alt={item.node.Description} onClick={() => {
                            setImageId(item.node.Id)
                            setIndex(index)
                          }} />
                        </li>
                      }) : ''
                    }

                  </ul>
                </div>

                {/* images description */}

                <p className='image-description'>{source[index]?.description}</p>
              </div>

            </div>
            <div className="right-column-container">

              <h1 className="product-name">{page.Name.toLowerCase()}</h1>

              <div className="product-description-panel">
                <CustomMarkdown children={page.Description} />
              </div>




              {/*===================================== */}


              {
                productOptions ? <div className="product-customisation">
                  <ul className="product-customisation-accordion" data-uk-accordion="collapsible: true">

                    {
                      productOptions.map((item) => {
                        if (item == 0) {
                          return <li className="uk-open">
                            {
                              materialsList.length === 1 || materialsList.length === 0 ? (materialsList[0] ? (<>
                                <div className="product-price">{prodPrice} <span className='vat'>inc. VAT</span></div>
                                <div className="material-selection"> Material: <img className="material-thumb" src={materialsList[0].materialImage ? process.env.GATSBY_IMAGE_PATH_URL + "/" + materialsList[0].materialImage : process.env.GATSBY_DEFAULT_NA_IMAGE} width="50" height="50" alt={materialsList[0].materialName.toLowerCase()} />{`   ${materialsList[0].materialName.toLowerCase()}`}</div>
                              </>) : (''//return nothing for no materials
                              ))
                                :
                                (
                                  <>
                                    <a className="uk-accordion-title" href="#0">1. Choose material</a>
                                    <div className="uk-accordion-content">
                                      <ul className="uk-thumbnav " data-uk-margin>
                                        {(materialsList.map((item) => {

                                          return (
                                            <li key={item.materialId} className="material-selection"><div
                                              onClick={() => materialHandle(item, item.materialId)}><img className="material-thumb" src={item.materialImage ? process.env.GATSBY_IMAGE_PATH_URL + "/" + item.materialImage : process.env.GATSBY_DEFAULT_NA_IMAGE} width="50" height="50" alt={item.materialName.toLowerCase()} />

                                              <span className={item.materialId === activeId ? 'active material-selection' : 'material-selection'}>
                                                {item.materialName.toLowerCase()}
                                              </span> </div></li>
                                          )
                                        })
                                        )}
                                      </ul>
                                    </div>
                                  </>

                                )
                            }

                          </li>
                        }


                        if (item == 1) {
                          return <li>

                            {
                              sizesList.length === 1 || sizesList.length === 0 ?
                                (
                                  !sizesList[0] ? (
                                    <div >
                                      <p>Dimensions: Width: {sizesList[0].width}cm x Height: {sizesList[0].height}cm x Length: {sizesList[0].length}cm</p>
                                    </div>) : (''))
                                : (<>

                                  <a className="uk-accordion-title" href="#0">2. Choose a size</a>
                                  <div className="uk-accordion-content">
                                    {/* <input className="uk-input" type="text" placeholder=""/> */}
                                    <ul className="uk-thumbnav ">
                                      {sizesList.map((item, index) => {
                                        {/* console.log('sizelist',sizesList) */ }
                                        return (
                                          <li key={index}>
                                            <label><input className="uk-radio" type="radio" name="radio" onClick={e => sizeHandle(item)} />
                                              Width: {item.width}cm x Height: {item.height}cm x Length: {item.length}cm.
                                            </label>
                                          </li>
                                        )
                                      })}

                                    </ul>
                                  </div>
                                </>
                                )
                            }



                          </li>
                        }


                        if (item == 2) {
                          return <li>

                            {
                              finishes.length === 1 ? (<div >

                                <p onClick={() => handleFinish(item)}> {finishes.FinishesName}</p>
                              </div>) : (<>

                                <a className="uk-accordion-title" href="#0">3. Choose a finish</a>
                                <div className="uk-accordion-content">

                                  <ul className="uk-thumbnav ">
                                    {finishes.map((item, index) => {

                                      return (
                                        <li key={index}>
                                          <label><input className="uk-radio" type="radio" value={item.FinishesId} name="radio-finish"
                                            onChange={() => handleFinish(item)} />
                                            {item.FinishesName}
                                          </label>
                                        </li>
                                      )
                                    })}

                                  </ul>
                                </div>
                              </>
                              )
                            }


                          </li>
                        }


                      })
                    }


                  </ul>
                </div> : ''

              }


              {/* 
             {/*===================================== */}




              {materialsList.length === 1 || page.HidePrices ? '' : <div className="product-price">{prodPrice} <span className='vat'>inc. VAT</span> </div>}


              {!page.HidePrices ? <div className="product-customisation">
                <ul className="product-customisation-accordion" uk-accordion="collapsible: false">
                  <li>
                    {
                      materialsList.length === 1 || materialsList.length === 0 ? (materialsList[0] ? (<>
                        <div className="product-price">{prodPrice} <span className='vat'>inc. VAT</span></div>
                        <div className="material-selection"> Material: <img className="material-thumb" src={materialsList[0].materialImage ? process.env.GATSBY_IMAGE_PATH_URL + "/" + materialsList[0].materialImage : process.env.GATSBY_DEFAULT_NA_IMAGE} width="50" height="50" alt={materialsList[0].materialName.toLowerCase()} />{`   ${materialsList[0].materialName.toLowerCase()}`}</div>
                      </>) : (''//return nothing for no materials
                      ))
                        :
                        (
                          <>
                            <a className="uk-accordion-title" href="#0">1. Choose material</a>
                            <div className="uk-accordion-content">
                              <ul className="uk-thumbnav " data-uk-margin>
                                {(materialsList.map((item) => {

                                  return (
                                    <li key={item.materialId} className="material-selection"><div
                                      onClick={() => materialHandle(item, item.materialId)}><img className="material-thumb" src={item.materialImage ? process.env.GATSBY_IMAGE_PATH_URL + "/" + item.materialImage : process.env.GATSBY_DEFAULT_NA_IMAGE} width="50" height="50" alt={item.materialName.toLowerCase()} />

                                      <span className={item.materialId === activeId ? 'active material-selection' : 'material-selection'}>
                                        {item.materialName.toLowerCase()}
                                      </span> </div></li>
                                  )
                                })
                                )}
                              </ul>
                            </div>
                          </>

                        )
                    }

                  </li>
                  <li>
                    {
                      sizesList.length === 1 || sizesList.length === 0 ?
                        (
                          !sizesList[0] ? (
                            <div >
                              <p>Dimensions: Width: {sizesList[0].width}cm x Height: {sizesList[0].height}cm x Length: {sizesList[0].length}cm</p>
                            </div>) : (''))
                        :
                        (<>

                          <a className="uk-accordion-title" href="#0">2. Choose a size</a>
                          <div className="uk-accordion-content">
                            {/* <input className="uk-input" type="text" placeholder=""/> */}
                            <div className="uk-margin uk-grid-small uk-child-width-auto" data-uk-grid>
                              {sizesList.map((item, index) => {
                                {/* console.log('sizelist',sizesList) */ }
                                return (
                                  <div key={index}>
                                    <label><input className="uk-radio" type="radio" name="radio" onClick={e => sizeHandle(item)} />
                                      Width: {item.width}cm x Height: {item.height}cm x Length: {item.length}cm.
                                    </label>
                                  </div>
                                )
                              })}

                            </div>
                          </div>
                        </>
                        )
                    }


                  </li>
                </ul>
              </div> : ''}




              {sizesList.length === 1 || page.HidePrices ? '' : <div className="product-price">{prodPrice} <span className='vat'>inc. VAT</span></div>}


              <div className="product-quantity">Qty.
                <button onClick={handleDecrement} >-</button>
                <span>{prodQty}</span>
                <button onClick={handleIncrement}>+</button>
              </div>


              <div className="product-add-btn">
                {/* <a onClick={() => addItem(product)} href="#0" aria-label="add to basket"><Button use="addToCart" label="Add to cart"></Button></a> */}
                <a onClick={() => {
                  addToCart(product, prodQty)
                  toggleAddedToBasket()
                  //handleGtag(page.Name)
                }} href="#popup1" aria-label="add to basket"><Button use="addToCart" label={page.HidePrices ? "Get a quote" : "Add to cart"}></Button></a>

              </div>

              <div className={(addedToBasket && page.HidePrices) ? "product__add__btn__message uk-animation-slide-top showMessage" : "product__add__btn__message"}>
                {/* <FaCheckCircle color={"#2E9F71"}/>
                Item added in the basket. Go to <Link to={"/checkout"}>Checkout</Link> to finish your enquiry */}



              </div>



            </div>{/** end of right column */}

          </div>
        </div>
      </div>

      {products.map(({ product, count }, i) => {

        orderProducts.push({
          "ItemId": product.itemId,
          "MaterialId": product.materialId,
          "SizeId": product.sizeId,
          "Quantity": 1,
          "HidePrices": product.hidePrice
        })
      })
      }

      {
        page.HidePrices ?
          <div id="popup1" className="overlay">
            <div className="popup">
              <p className="popup-title" id="modalTitle">Please enter your details so our team can contact you, or <a className="cont-browsing" href="#">continue browsing.. </a> to add more items on your quote.</p>
              <a className="close" href="#">&times;</a>
              <div className="content">
                <hr id="divider" />
                <section id="checkout-customer-details " ref={checkoutCustomerDetails}>
                  <div className="checkout-customer-details ">
                    <h2>Your details</h2>
                    <h5>All fields are required</h5>
                    <form className="uk-grid-large " id="memorials-checkout" onSubmit={sendOrderTest} data-uk-grid>
                      <fieldset className="uk-fieldset uk-width-1-1">

                        <div className="" data-uk-grid>
                          <div className="uk-margin uk-width-1-2 form-field">
                            <label className="uk-form-label" htmlFor="form-stacked-text">First Name</label>
                            <input id="order-first-name" className="uk-input" type="text" placeholder="Example: John" required />
                          </div>

                          <div className="uk-margin uk-width-1-2 form-field" id="no-padding-right">
                            <label className="uk-form-label" htmlFor="form-stacked-text">Surname</label>
                            <input id="order-surname" className="uk-input" type="text" placeholder="Example: Doe" required />
                          </div>
                        </div>

                        <div className="uk-margin uk-width-1-1">
                          <label className="uk-form-label" htmlFor="form-stacked-text">Salutation</label>
                          {/* <input id="order-salutation" className="uk-input" type="text" placeholder="Example: Mr. John" required/> */}
                          <select id="order-salutation" className="uk-input" placeholder="Select title">
                            <option value={""}>―Select an option―</option>
                            <option value={"Mr"}>Mr</option>
                            <option value={"Miss"}>Miss</option>
                            <option value={"Mrs"}>Mrs</option>
                            <option value={"Ms"}>Ms</option>
                            <option value={"Mr & Mrs"}>Mr &amp; Mrs</option>
                            <option value={"Dr"}>Dr</option>
                            <option value={"Rev"}>Rev</option>
                            <option value={"Professor"}>Professor</option>
                            <option value={"Lord"}>Lord</option>
                            <option value={"Lady"}>Lady</option>
                            <option value={"Sir"}>Sir</option>
                          </select>
                        </div>

                        <div className="divider"></div>

                        <div className="" data-uk-grid>
                          <div className="uk-margin uk-width-1-2 form-field">
                            <label className="uk-form-label" htmlFor="form-stacked-text">Email address</label>
                            <input id="order-email" className="uk-input" type="text" placeholder="Example: name@example.com" required />
                          </div>

                          <div className="uk-margin uk-width-1-2 form-field" id="no-padding-right">
                            <label className="uk-form-label" htmlFor="form-stacked-text">Phone No</label>
                            <input id="order-phone" className="uk-input" type="text" placeholder="Example: 012 3456 7890" required />
                          </div>
                        </div>

                        <div className="" data-uk-grid>
                          <div className="uk-margin uk-width-1-2 form-field">
                            <label className="uk-form-label" htmlFor="form-stacked-text">Address</label>
                            <input id="order-address" className="uk-input" type="text" placeholder="Example: London, 91 Western Road" required />
                          </div>

                          <div className="uk-margin uk-width-1-2 form-field" id="no-padding-right">
                            <label className="uk-form-label" htmlFor="form-stacked-text">Postcode</label>
                            <input id="order-postcode" className="uk-input" type="text" placeholder="Ex: DA8 3NU" required />
                          </div>
                        </div>

                        {/* <div className="divider"></div> */}

                        <div className="uk-margin uk-width-1-1">
                          <label className="uk-form-label" htmlFor="form-stacked-text">Cemetery/Churchyard</label>
                          <input id="order-cemetery" className="uk-input" type="text" placeholder="Cemetery name" />
                        </div>

                      </fieldset>

                      <div className="">
                        <div id="confirm-order" onClick={() => handleGtag(page.Name)}><Button use="addToCart" label="Confirm Quote"></Button></div>
                      </div>
                    </form>
                  </div>
                </section>

                <section id="checkout-response " ref={checkoutResponse}>
                  <div className="checkout-response uk-hidden padding-top padding-bottom">
                    {/* <h2>Order complete</h2> */}
                    <h3>Thank you for your enquiry, we are processing your quotation request and will be in touch shortly.</h3>



                    <div className="" data-uk-grid>
                      <div className="uk-padding-remove uk-width-1-1">

                        <div className="checkout-response-field">
                          <div>Reference number: <span id="order-response-number"></span></div>
                          {/* <div><a id="order-response-payment-url">Proceed to payment</a></div> */}
                        </div>
                        <div className="divider"></div>
                        <div className="">
                          <Link to="/collections">
                            <Button use="" label="Continue browsing"></Button>
                          </Link>
                        </div>



                      </div>
                    </div>
                  </div>
                </section>


              </div>
            </div>
          </div>
          : ""
      }
    </Layout>
  )
}

function mapStateToProps({ cartReducer }) {
  return {
    products: getAllProducts(cartReducer)
  };
}

const mapDispatchToProps = {
  addToCart: (product, count) => addToCartMessage(product, count),
  emptyCart: product => emptyCartMessage(product),
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Products);